import { KbaRequestEvent } from './../data/admin.data';
import { MetaInformation } from './../data/metaInformation.data';
import { ILogService, IRootScopeService } from "angular";
import { InputPlugin } from "../data/input.data";
import {
  EWebSocketType,
  WebSocketData,
  WebSocketLogin,
  WebSocketServerModeEvent,
  WebSocketServerStateEvent
} from "../data/socket.data";
import { StatusEntryResponse, VehicleLocationChangeData } from "../data/vehicles.data";
import { MissionRemovedFromMapResponse, VehicleAssignedAlarm, VehicleAssignedAlarmCombined, VehicleRemovedFromAlarm } from '../data/alarm.data';
import { Queue, QueueEntryResponse } from '../data/queue.data';
import { IOPrintsInfoResponse } from "../data/ioprint.data";

'use strict';


/* @ngInject */
export default class SocketService {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public restService;

  public connection: WebSocket;

  constructor($log: ILogService, $rootScope: IRootScopeService, restService) {
    $log.debug('Init SocketService');
    this.restService = restService;
    this.$log = $log;
    this.$rootScope = $rootScope;
  }

  reconnect() {
    this.$log.info('Reconnecting websocket...');
    if (this.connection && this.connection.readyState === this.connection.OPEN) {
      const reconnect = {
        jwt: this.restService.getToken()
      } as WebSocketLogin;
      this.$log.info('Sending LOGIN...');
      this.connection.send(JSON.stringify(reconnect));
    } else {
      this.login();
    }
  }


  login() {
    this.$log.info('Start websocket connection...');

    var baseUrl = this.restService.getBaseUrl();

    //for production: set current location
    if (!baseUrl.startsWith('http')) {

      var loc = window.location;
      if (loc.protocol === "https:") {
        baseUrl = "wss://";
      } else {
        baseUrl = "ws://";
      }
      baseUrl += loc.host + "/";
    }

    //in dev mode: baseUrl contains already the backend host and port (e.g. http://localhost:83)
    else {
      baseUrl = baseUrl.replace(/rest/, '');
      baseUrl = baseUrl.replace(/https/, 'wss');
      baseUrl = baseUrl.replace(/http/, 'ws');
    }

    baseUrl = baseUrl + 'live?jwt=' + this.restService.getToken();

    if (this.connection) {
      this.connection.close();
    }

    this.connection = new WebSocket(baseUrl, []);


    this.connection.onopen = () => {
      this.$log.info('Websocket connected!');
      this.$rootScope.$broadcast('websocket.connection', {
        isConnected: true
      });
    };


    this.connection.onmessage = (data) => {
      var payload: WebSocketData = JSON.parse(data.data) as WebSocketData;
      this.$log.info('Websocketevent: ' + payload.type);
      this.$log.info(payload);

      switch (payload.type) {
        case EWebSocketType.STATUS:
          var status = JSON.parse(payload.json) as StatusEntryResponse;
          this.$log.info(`Incoming status change for ${status.vehicleName}: ${status.status} (${status.vehicleId})`);
          this.$rootScope.$emit('status.change.' + status.vehicleId, status);
          this.$rootScope.$emit('status.change', status);
          break;
        case EWebSocketType.RELAIS:
          var json = JSON.parse(payload.json);
          this.$log.info('Incoming relais change for ' + json.id);
          this.$rootScope.$emit('relais.change.' + json.id, json);
          break;
        case EWebSocketType.INPUT:
          let inputPlugin = JSON.parse(payload.json) as InputPlugin;
          this.$log.info('Incoming input state change for ' + inputPlugin.id);
          this.$rootScope.$emit('input.change.' + inputPlugin.id, inputPlugin);
          this.$rootScope.$emit('input.change', inputPlugin);
          break;
        case EWebSocketType.ALARM:
          var json = JSON.parse(payload.json);
          this.$log.info('New alarm for user');
          this.$rootScope.$emit('new.alarm', json);
          // Inform for single alarm
          if (json.externalId) {
            this.$rootScope.$emit(`new.alarm.${json.externalId}`, json);
          }

          break;
        case EWebSocketType.SERVER_STATE:
          const serverState: WebSocketServerStateEvent = JSON.parse(payload.json) as WebSocketServerStateEvent;
          this.$log.info('Server-State: ' + serverState.state);
          this.$rootScope.$emit('server.state', serverState);
          break;
        case EWebSocketType.AMOBILE_CHANGED:
          var json = JSON.parse(payload.json);
          this.$rootScope.$emit('amobile.change.' + json.aMobileId, json.data);
          break;
        case EWebSocketType.SERVER_MODE_STATE:
          const serverMode: WebSocketServerModeEvent = JSON.parse(payload.json) as WebSocketServerModeEvent;
          this.$log.info('Server-Mode: ' + serverMode.mode);
          this.$rootScope.$emit('server.mode', serverMode);
          break;
        case EWebSocketType.META_INFO_UPDATED:
          const metaInformation = JSON.parse(payload.json) as MetaInformation;
          this.$rootScope.$emit('metainformation.change', metaInformation);
          break;
        case EWebSocketType.VEHICLE_ASSIGNED_TO_ALARM:
          const assignedAlarm = JSON.parse(payload.json) as VehicleAssignedAlarm;
          this.$rootScope.$emit('new.assignedAlarm', assignedAlarm);
          break;
        case EWebSocketType.VEHICLE_ASSIGNED_TO_ALARM_COMBINED:
          const assignedAlarmCombined = JSON.parse(payload.json) as VehicleAssignedAlarmCombined;
          this.$rootScope.$emit('new.assignedAlarm.combined', assignedAlarmCombined);
          break;
        case EWebSocketType.VEHICLE_REMOVED_FROM_ALARM:
          const removedVehicle = JSON.parse(payload.json) as VehicleRemovedFromAlarm;
          this.$rootScope.$emit('deleted.assignedAlarm', removedVehicle);
          break;
        case EWebSocketType.KBA_REQUEST:
          const kbaRequestEvent = JSON.parse(payload.json) as KbaRequestEvent;
          this.$rootScope.$emit('new.kba.request', kbaRequestEvent);
          break;
        case EWebSocketType.MISSION_REMOVED_FROM_MAP:
          const removedMission = JSON.parse(payload.json) as MissionRemovedFromMapResponse;
          this.$rootScope.$emit('finished.mission', removedMission);
          break;
        case EWebSocketType.ICAL_CALENDAR_CHANGED:
          // synchronized calendar changed, call event to reload appointments in displayed timeframe
          this.$rootScope.$emit('events.updated');
          break;
        case EWebSocketType.QUEUE_ENTRY_ADDED:
        case EWebSocketType.QUEUE_ENTRY_REMOVED:
        case EWebSocketType.QUEUE_ENTRY_UPDATED:
          const queueEvent = (JSON.parse(payload.json).entry) as QueueEntryResponse;
          this.$rootScope.$emit(`queue.${queueEvent.queueId}.update`, queueEvent);
          this.$rootScope.$emit(`queue.${payload.type}`, queueEvent);
          break;
        case EWebSocketType.LAGEKARTE_UPDATED:
          const newTaskForQueueEntryData = payload.json as string;
          this.$rootScope.$emit(`${payload.type}`, newTaskForQueueEntryData);
          break;
        case EWebSocketType.VEHICLE_LOCATION_CHANGED:
          const vehicleLocationChangeData = JSON.parse(payload.json) as VehicleLocationChangeData;
          this.$rootScope.$emit('vehicle.location.change', vehicleLocationChangeData);
          break;
        case EWebSocketType.QUEUE_UPDATED:
          const queueResponse = (JSON.parse(payload.json)) as Queue;
          this.$rootScope.$emit(`queue.${queueResponse.id}.update.state`, queueResponse);
          break;
        case EWebSocketType.IOPRINT:
          const ioPrint = (JSON.parse(payload.json)) as IOPrintsInfoResponse;
          this.$log.info('ioprint.update: ' + ioPrint.id);
          this.$rootScope.$emit(`ioprint.update.state`, ioPrint);
          break;

      }
    };



    this.connection.onclose = (event: CloseEvent) => {
      const code = event.code;
      const reason = event.reason;
      this.$log.info('Websocket disconnected (' + code + '): ' + reason);

      this.$rootScope.$broadcast('websocket.connection', {
        isConnected: false
      });

      this.$log.info('Attempting reconnect in 10s...');

      var _self = this;
      setTimeout(function () {
        _self.login();
      }, 10000);

    };
  }
}
